<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addUserForm" :slim="true">
          <md-card-content>
            <div class="md-layout md-gutter">
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
                name="emoji"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Предмет</label>
                  <md-file
                    v-model="file"
                    accept=".svg, .apng, .gif"
                    @input="handleChange"
                  />
                  <span class="md-helper-text">
                    Файл должен быть форматов GIF, APNG, SVG и весить от 2 до
                    200 килобайт
                  </span>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Цена</label>
                  <md-input v-model="cost" type="number" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Скидка</label>
                  <md-input v-model="discount" type="number" />
                  <span class="md-suffix">%</span>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Раздел</label>
                  <md-select v-model="dirChoose">
                    <template v-for="dir in dirs">
                      <md-optgroup
                        v-if="dir.group"
                        :key="`option_role_group_${dir.id}`"
                        :label="dir.title"
                      >
                        <md-option
                          v-for="dir_group in dir.group"
                          :key="`option_role_option_${dir_group.id}`"
                          :value="dir_group.id"
                        >
                          {{ dir_group.title }}
                        </md-option>
                      </md-optgroup>
                      <md-option
                        v-else
                        :key="`option_role_option_${dir.id}`"
                        :value="dir.id"
                      >
                        {{ dir.title }}
                      </md-option>
                    </template>
                  </md-select>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="buttons-wrap justify-end">
              <md-button
                class="md-raised md-danger"
                @click="$router.push('/market/list')"
              >
                Отменить
              </md-button>
              <md-button class="md-raised md-success"> Создать </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cost: null,
      discount: null,
      dirChoose: null,
      file: null,
      isValid: false,
      dirs: [
        {
          id: "emoji",
          title: "Эмоции",
        },
        {
          id: "fon",
          title: "Фон для игрока",
        },
        {
          id: "game_zariki",
          title: "Игра зарики",
          group: [
            {
              id: "zariki",
              title: "Зарики",
            },
            {
              id: "game_field",
              title: "Игровое поле",
            },
          ],
        },
        {
          id: "game_slot",
          title: "Игра слот-машины",
          group: [
            {
              id: "icon_drums",
              title: "Иконки барабанов",
            },
            {
              id: "slot_machine",
              title: "Слот-машины",
            },
            {
              id: "lever_slot",
              title: "Рычаг слот-машины",
            },
          ],
        },
        {
          id: "game_fool",
          title: "Игра дурак",
          group: [
            {
              id: "shirt_card",
              title: "Рубашки карт",
            },
            {
              id: "face_card",
              title: "Лицевая сторона карт",
            },
            {
              id: "game_table",
              title: "Игровой стол",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleChange(e) {
      if (e.target) {
        const file = e.target.files[0];
        if (file && (file.size < 2 * 1024 || file.size > 200 * 1024)) {
          this.$refs.addEmoji.setErrors({
            emoji: "Ошибка. Проверьте загружаемый файл",
          });
          this.isValid = true;
        } else {
          this.$refs.addEmoji.setErrors({
            emoji: null,
          });
          this.isValid = false;
        }
      }
    },
  },
};
</script>

<style>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}
</style>
