var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('ValidationObserver',{ref:"addUserForm",attrs:{"slim":true}},[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required","name":"emoji"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Предмет")]),_c('md-file',{attrs:{"accept":".svg, .apng, .gif"},on:{"input":_vm.handleChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('span',{staticClass:"md-helper-text"},[_vm._v(" Файл должен быть форматов GIF, APNG, SVG и весить от 2 до 200 килобайт ")])],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Цена")]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.cost),callback:function ($$v) {_vm.cost=$$v},expression:"cost"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Скидка")]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}}),_c('span',{staticClass:"md-suffix"},[_vm._v("%")])],1)]}}])}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-100",attrs:{"mode":"passive","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Раздел")]),_c('md-select',{model:{value:(_vm.dirChoose),callback:function ($$v) {_vm.dirChoose=$$v},expression:"dirChoose"}},[_vm._l((_vm.dirs),function(dir){return [(dir.group)?_c('md-optgroup',{key:("option_role_group_" + (dir.id)),attrs:{"label":dir.title}},_vm._l((dir.group),function(dir_group){return _c('md-option',{key:("option_role_option_" + (dir_group.id)),attrs:{"value":dir_group.id}},[_vm._v(" "+_vm._s(dir_group.title)+" ")])}),1):_c('md-option',{key:("option_role_option_" + (dir.id)),attrs:{"value":dir.id}},[_vm._v(" "+_vm._s(dir.title)+" ")])]})],2)],1)]}}])})],1),_c('div',{staticClass:"buttons-wrap justify-end"},[_c('md-button',{staticClass:"md-raised md-danger",on:{"click":function($event){return _vm.$router.push('/market/list')}}},[_vm._v(" Отменить ")]),_c('md-button',{staticClass:"md-raised md-success"},[_vm._v(" Создать ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }